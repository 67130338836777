<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { authHeader } from "../../../helpers/auth-header";

import FilterModal from "@/components/modals/filter-modal.vue";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import ListingTable from "@/components/listings/listing-table";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";

export default {
    components: {
        Layout,
        PageHeader,
        FilterModal,
        ConfirmationModal,
        MessageModal,
        ListingTable,
        InputGroup,
        SelectGroup,
    },
    data() {
        return {
            title: "Student Grade",
            items: [
                {
                    text: "List"
                },
            ],
            filter: {
                name: '',
                status: 2,
                selected_role: '',
            },
            statusOptions: [
                { name: 'All', value: 2 },
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            fields: [
                { key: "name", sortable: true, label: "Name" },
                { key: "status", label: "Status" },
                { key: "action" }
            ],
            addUrl: `${window.location.origin}/field_management/student_grade/form/add`,
            isModalVisible: false,
        };
    },
    methods: {
        deleteRecord(id, name) {
            const deleteCallback = async () => {
                await axios.delete(`${process.env.VUE_APP_APIURL}/field_management/student_grade/${id}`, {
                    headers: authHeader(),
                });
                this.filterTable(this.filter);
                this.$refs.confirmationModal.closeModal();
                this.$refs.messageModal.showModal('The record has been deleted');
            }
            this.$refs.confirmationModal.showModal(`Confirm delete ${name}?`, deleteCallback);
        },
        filterTable(filters) {
			const filter = JSON.parse(JSON.stringify(filters));
			this.$refs.listingTable.filterTable(filter);
		},
		resetTable() {
			this.filter = {
				name: '',
				status: 2
			};
			this.$refs.listingTable.filterTable(this.filter);
		},
		showFilterModal() {
			this.$refs.filterModal.showModal();
		},
		hideModal() {
			this.isModalVisible = false;
		},
		handleSubmit() {
			this.filterTable(this.filter);
			this.$refs.filterModal.closeModal();
		}
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />

        <FilterModal ref="filterModal" :isVisible="isModalVisible" @close="hideModal">
			<form @submit.prevent="handleSubmit">
				<div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.name" type="text" id="name" displayName="Student grade" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.status" id="status" displayName="Status" :options="statusOptions" />
                        </div>
                    </div>
				</div>

				<div class="modal-footer">
                    <button type="button" class="clear__btn" @click="resetTable">Clear</button>
                    <button type="submit" class="save__btn">Filter</button>
                </div>
			</form>
		</FilterModal>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0" style="position: relative;">
                        <div class="filter_buttons">
							<button class="filter_button" @click="showFilterModal">
                                <i class="mdi mdi-filter-variant mr-2 font-size-17"></i>
                                Filter
                            </button>
							<a class="add_new_button" :href="addUrl">
                                <i class="mdi mdi-plus mr-2 font-size-17"></i> 
                                Add New Student Grade
                            </a>
						</div>

                        <ListingTable ref="listingTable" :fields="fields" path="field_management/student_grade" listName="student_grades">
                            <template v-slot:cell(status)="row">
                                <div class="font-size-12" :class="{
                                    'status-red': parseInt(`${row.value}`) === 0,
                                    'status-green': parseInt(`${row.value}`) === 1,
                                }">{{ parseInt(row.value) === 1 ? 'Active' : 'Inactive' }}</div>
                            </template>
                            <template v-slot:cell(action)="{ item }">
                                <router-link class="mr-3 text-primary" :to="{name: 'Student Grade Form', params: {id: item.id}}">
                                    <i class="mdi mdi-pencil font-size-18" v-b-tooltip.hover title="Edit"></i>
                                </router-link>

                                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete" @click="deleteRecord(item.id, item.name)">
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </template>
                        </ListingTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style scoped>
.modal-footer {
    padding-top: 24px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 48px;
    justify-content: space-between;
    border-top: 1px solid rgba(205, 205, 205, 0.5);

    & .clear__btn {
        border: 0;
        background-color: transparent;
        font-family: Nunito;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #505D69;
        text-decoration: underline;
    }

    & .save__btn {
        border: 1px solid #004AAD;
        border-radius: 6px;
        background: #004AAD;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
    }
}
</style>